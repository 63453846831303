
import Vue from "vue";

import VerifyCodeDialog from '@/components/common/VerifyCodeDialog.vue';
import UniqueEmailDialog from '@/components/common/UniqueEmailDialog.vue';
import CountryDropdown from '@/components/common/inputs/CountryDropdown.vue';
import StateDropdown from '@/components/common/inputs/StateDropdown.vue';
import InternationalPhoneNumberInput from '@/components/common/inputs/InternationalPhoneNumberInput.vue';

import personService from "@/services/portal/personService";
import authenticationService from "@/services/authenticationService";
import { VerificationType } from "@/services/codeVerificationService";

import { UserInfo } from "@/types/User";
import { ContactInfo } from "@/types/portal/Person";

import validateEmail from '@/helpers/validate-email';

export default Vue.extend({
  name: "ContactInfo",
  components: {
    VerifyCodeDialog,
    CountryDropdown,
    StateDropdown,
    UniqueEmailDialog,
    InternationalPhoneNumberInput,
  },
  data: function () {
    return {
      errorMessage: "",
      errorMessageInsideModal: "",
      showNonUniqueEmail: false,
      isValid: false,
      isValidPhone: false,
      contactInfo: <ContactInfo>{
        firstName: "",
        lastName: "",
        address: {
          street: "",
        }
      },
      persistedContactInfo: <ContactInfo>{},
      showLoader: false,
      showEmailCodeDialog: false,
      showSmsCodeDialog: false,
    };
  },
  created: function () {
    this.loadUserContactInfo();
  },
  watch: {
    userInfo: function (newVal, oldVal) {
      if (newVal === oldVal) return;

      this.loadUserContactInfo();
    },
    "contactInfo.phoneNumber": function (newVal, oldVal) {
      if (newVal != this.persistedContactInfo.phoneNumber) {
        this.contactInfo.phoneNumberVerified = false;
      } else {
        this.contactInfo.phoneNumberVerified =
          this.persistedContactInfo.phoneNumberVerified;
      }
    },
    "contactInfo.email": function (newVal, oldVal) {
      if (newVal != this.persistedContactInfo.email) {
        this.contactInfo.emailVerified = false;
      } else {
        this.contactInfo.emailVerified =
          this.persistedContactInfo.emailVerified;
      }
    },
  },
  computed: {
    userInfo(): UserInfo {
      return this.$store.state.userInfo;
    },
    emailVerificationType: function () {
      return VerificationType.Email;
    },
    phoneNumberVerificationType: function () {
      return VerificationType.PhoneNumber;
    },
  },
  methods: {
    validateEmail(v: string) {
      return (
        validateEmail(v) ||
        "E-mail must be valid"
      );
    },
    validateCity(v: string) {
      return this.contactInfo.address.country === 'UNITED STATES'
        ? !!v || 'City is required'
        : true
    },
    validateZip(v: string) {
      return this.contactInfo.address.country === 'UNITED STATES'
        ? !!v || 'Zip/Postal is required'
        : true
    },
    updateCountry: async function (v: string) {
      this.contactInfo.address.country = v;

      await Vue.nextTick();

      (this.$refs.contactForm as any).validate();
    },
    updateContactInfo: function () {
      this.showLoader = true;
      personService
        .changeAddressInformation(
          this.userInfo.personId,
          this.$data.contactInfo
        )
        .then((res) => {
          if (res.errorMessage) {
            this.errorMessage = res.errorMessage;
            this.showLoader = false;
          } else {
            this.errorMessage = "";
            this.loadUserContactInfo();
          }
        });
    },
    closeEmailCodeDialog: function () {
      this.showEmailCodeDialog = false;
    },
    emailVerifiedSuccessfully: function () {
      this.contactInfo.emailVerified = true;
      this.showEmailCodeDialog = false;

      this.showLoader = true;
      personService
        .changeContactInformation(
          this.userInfo.personId,
          this.$data.contactInfo
        )
        .then((res) => {
          if (res.errorMessage) {
            this.errorMessage = res.errorMessage;
            this.showLoader = false;
          } else {
            this.errorMessage = "";
            this.loadUserContactInfo();
          }
        });
    },
    closeSmsCodeDialog: function () {
      this.showSmsCodeDialog = false;
    },
    phoneNumberVerifiedSuccessfully: function () {
      this.contactInfo.phoneNumberVerified = true;
      this.showSmsCodeDialog = false;

      this.showLoader = true;
      personService
        .changeContactInformation(
          this.userInfo.personId,
          this.$data.contactInfo
        )
        .then((res) => {
          if (res.errorMessage) {
            this.errorMessage = res.errorMessage;
            this.showLoader = false;
          } else {
            this.errorMessage = "";
            this.loadUserContactInfo();
          }
        });
    },
    checkEmail: async function(newEmail?: string) {
      if (this.contactInfo.email === this.userInfo.email) return true;

      if (!!newEmail) this.contactInfo.email = newEmail;

      const response = await authenticationService.uniqueEmail(this.contactInfo.email);

      console.log(response);

      this.showNonUniqueEmail = !response.unique;

      return response.unique;
    },
    verifyEmail: async function() {
      const uniqueEmail = await this.checkEmail();

      if (uniqueEmail) this.showEmailCodeDialog = true
    },
    canSubmit: function () {
      return (
        JSON.stringify(this.contactInfo) !==
          JSON.stringify(this.persistedContactInfo)
        && this.isValid
      );
    },
    loadUserContactInfo: function () {
      this.showLoader = true;
      personService
        .getContactInformation(this.userInfo.personId)
        .then((res) => {
          if (res.found) {
            this.contactInfo = Object.assign({}, res.data);
            this.persistedContactInfo = Object.assign({}, res.data);
            this.persistedContactInfo.address = Object.assign(
              {},
              res.data.address
            );
          } else {
            this.errorMessage = "Couldn't load the person contact information.";
          }
          this.showLoader = false;
        })
        .catch((e) => console.log(e));
    },
  },
});
